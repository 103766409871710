@import 'styles/mixins.scss';
@import 'styles/variable.scss';

.bannerCarousel {
  width: 560px;
  position: relative;

  @include msd {
    width: 100%;
    display: none;
  }
}

.banner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  overflow: hidden;
  height: 274px;

  @include sm {
    margin-top: 12px;
    height: auto;
  }
}

.bannerViewport {
  overflow: hidden;
  width: 100%;

  @include msd {
    height: 274px;
  }
}

.bannerContainer {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.bannerSlide {
  position: relative;
  min-width: 100%;
  padding-left: 10px;
}

.bannerSlideInner {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 12px;
  height: 274px;
  width: 560px;

  @include sm {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
  }

  @include lg {
    height: 274px;
    width: 560px;
  }

  @include msd {
    height: 274px;
    width: 100%;
  }
}

.bannerSlideImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: none;
  background-color: rgba($white-1, 0.9);
  margin-bottom: 48px;
  transform: translate(-50%, -50%);
  object-fit: contain;

  @include lg {
    object-fit: cover;
  }
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  color: $primaryBlue;
  width: 100%;
  height: 100%;
}

.embla__button__prev {
  left: 27px;
}

.embla__button__next {
  right: 27px;
}

.bannerDots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%);
  z-index: 12;
}

.bannerDot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  display: flex;
  align-items: center;
}

.bannerDot:after {
  background-color: #efefef;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  content: '';
}

.bannerDot.is_selected:after {
  background-color: $primaryBlue;
  opacity: 1;
}