@import 'styles/mixins';
@import 'styles/variable';

$contentWidth: 90vw;
$searchContentWidth: 820px;

.container {
  background: #fcfcfc;
}

.companiesWrapper {
  padding-top: 20px;
  background-color: #f7f8fa;

  @include sm {
    padding-top: 0;
  }
}

.companies {
  @extend .GlobalContainer;

  padding-bottom: 88px;

  @include sm {
    padding-bottom: 60px;
  }

  &Pagination {
    display: flex;
    margin-top: 50px;
  }

  @include msd {
    width: $contentWidth;
    padding-right: 0 !important;
    padding-bottom: 0;
    padding-left: 0 !important;
  }
}

.searchContainer {
  position: relative;
  background: #f0f4f7;
}

.searchCompany {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  transform: translateY(64px);

  @extend .GlobalContainer;

  > p {
    margin-top: 32px;
  }

  &Title {
    margin-bottom: 24px;
  }
}

.companiesPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  :global {
    .MuiPaginationItem-page {
      background-color: #fff;
    }

    .MuiPaginationItem-root.Mui-selected {
      background-color: #1976d2;
    }
  }

  @include msd {
    display: none;
  }
}

.filters {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  width: $searchContentWidth;
  background: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 0 0 8px 8px;

  // overflow: hidden;

  @include sm {
    justify-content: end;
  }

  @include msd {
    // padding: 10px;
    flex-wrap: wrap;
    width: 90vw;
    min-height: 78px;
  }

  .filterItems {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 64px;

    &:hover {
      background-color: #f2f3f5;

      :global {
        .MuiOutlinedInput-root {
          background-color: #f2f3f5 !important;
        }

        .MuiOutlinedInput-input {
          background-color: #f2f3f5 !important;
        }
      }
    }

    @include msd {
      // flex: 0 1 48%;
      // margin: 0 8px 15px 0;

      // &:nth-child(even) {
      //   margin-right: 0;
      // }

      flex: inherit;
      width: 50%;
      height: 38px;

      &:hover {
        background-color: transparent !important;

        :global {
          .MuiOutlinedInput-root {
            background-color: transparent !important;
          }

          .MuiOutlinedInput-input {
            background-color: transparent !important;
          }
        }
      }
    }

    :global {
      .MuiSelect-select {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      .MuiPaper-root {
        width: 300px;

        .MuiAutocomplete-paper {
          width: 300px;
        }
      }

      .MuiSelect-select div {
        width: calc(100% - 13px);
        overflow: hidden;
        line-height: 1.5;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .MuiOutlinedInput-root {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #fafafa !important;
        border-radius: 4px !important;

        &:hover {
          background-color: #f2f3f5 !important;
        }
      }

      .MuiOutlinedInput-input {
        padding-right: 24px !important;
        font-size: 16px;
        background: #fafafa !important;

        &:hover {
          background-color: #f2f3f5 !important;
        }
      }
    }
  }

  .clearButton {
    align-items: center;
    justify-content: flex-end;
    width: 156px;
    height: 64px;
    padding-right: 20px;
    font-family: 'Product Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    color: #86909c;
    text-transform: capitalize !important;
    letter-spacing: 0.0075em;

    &:hover {
      background-color: #f2f3f5 !important;

      .reset {
        transition: all 0.3s linear;
        transform: rotate(180deg);
      }
    }

    @include msd {
      justify-content: center;
      width: 50%;
      height: 44px;

      &:hover {
        background-color: transparent !important;

        .reset {
          transition: all 0.3s linear;
          transform: rotate(180deg);
        }
      }

      // margin: 0 8px 15px 0;
    }
  }
}

.featuredCompanyWrapper {
  padding-bottom: 80px;
  background-color: #fff;

  @include msd {
    padding-bottom: 20px;
  }
}

.featuredCompanyMain {
  @extend .GlobalContainer;

  width: $contentWidth;
  padding: 0 !important;
}

.featuredEmployer {
  display: flex;
  justify-content: space-between;

  @include msd {
    flex-direction: column;
    height: auto;
    margin-top: 20px;
  }
}

.featuredCompany {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 274px;
  padding: 16px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #e5e6eb;
  border-radius: 8px;

  @include sm {
    width: 100%;
    height: auto;
    padding: 12px;
  }
}

.featuredCompanyVerified {
  position: relative;
  width: 40px;
  height: 40px;

  @include msd {
    width: 32px;
    height: 32px;
  }
}

.featuredCompanyLogo {
  width: 90px;
  height: 90px;
  user-select: none;

  a {
    display: inline-block;
  }
}

.featuredTitleWrapper {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.featuredCompanyLogoLink {
  // border: 1px solid #f2f5fa;
  position: relative;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 5px;
}

.featuredCompanyDetails {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  margin-top: 20px;

  @include msd {
    width: 100%;
  }
}

.featuredCompanyAbout {
  max-width: 430px;

  // padding-top: 8px;
  padding-bottom: 10px;
  overflow: hidden;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include msd {
    max-width: 100%;
  }
}

.featuredCompanyAboutItem {
  margin-right: 8px;
  font-family: 'Product Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--main-color-black-black-037-d-7-d-7-d, #7d7d7d) !important;

  /* 100% */
  letter-spacing: 0.105px;
}

.featuredCompanyAboutItemSeparator {
  display: inline-block;
  width: 1px;
  height: 9px;
  margin-left: 4px;
  background-color: #c9cdd4;
}

.featuredCompanyNameWrapper {
  display: flex;
  align-items: center;

  img {
    margin-top: 3px;
  }
}

.featuredCompanyName {
  display: inline-block;
  max-width: 408px;
  margin-right: 8px;
  margin-bottom: 10px;
  overflow: hidden;

  /* Bold/super title/medium */
  font-family: 'Product Sans';
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  font-weight: 700;
  line-height: 30px;
  color: var(--main-color-black-black-01121212, #121212);
  text-overflow: ellipsis;

  /* 125% */
  letter-spacing: 0.24px;
  white-space: nowrap;

  @include msd {
    max-width: 100%;
    white-space: wrap;
  }
}

.featuredCompanyDescription {
  display: -webkit-box;
  max-width: 430px;
  height: 48px;
  margin: 0;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #4e5969;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include msd {
    max-width: 100%;
  }
}

.featuredCompanyPhotos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.featuredCompanyPhoto {
  position: relative;
  width: 136px;
  height: 94px;
  margin-right: 8px;
  user-select: none;
  -webkit-user-drag: none;
  border: 1px solid rgba(229, 230, 235, 0.3);

  &:last-child {
    margin-right: 0;
  }

  @include msd {
    flex: 0 1 30%;
    height: 0;
    padding-bottom: 25%;
  }
}

.placeholderSpace {
  height: 60px;
  background-color: #fff;

  @include msd {
    height: 60px;
  }
}

.featuredSectionTitle {
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  color: #353535;
  text-align: center;
  letter-spacing: 0.33px;

  @include sm {
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 32px;
  }

  @include msd {
    display: none;
  }
}

.popularCompanyTitle {
  padding-top: 30px;
  padding-bottom: 60px;
  margin: 0;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  color: #353535;
  text-align: center;
  letter-spacing: 0.33px;

  @include msd {
    padding-top: 30px;
    padding-bottom: 0;
    font-size: 32px;
  }
}

.searchField {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $searchContentWidth;
  padding: 14px;
  background: #fff;
  border-radius: 8px 8px 0 0;

  // box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);

  @include msd {
    width: 90vw;
    padding: 4px;
  }

  &InputContainer {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    background: var(--background-white, #fff);
    border-radius: 8px 0 0 8px;

    :global {
      .MuiOutlinedInput-root {
        height: 60px;
        background-color: #fff;
        border-radius: 8px 0 0 8px;
      }

      .MuiOutlinedInput-input {
        font-size: 16px;
      }

      .MuiInputLabel-root {
        top: 12px;
      }

      .MuiFormLabel-filled,
      .Mui-focused {
        top: 0;
        border-radius: 8px 0 0 8px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-width: 0;
        border-radius: 8px 0 0 8px;

        // border-width: 1px;
        // border-color: #E5E6EB;
      }

      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #1976d2;
        border-width: 2px;
      }

      .MuiAutocomplete-input {
        padding: 2.5px 28px 2.5px 6px !important;
      }
    }
  }

  &Input {
    width: 100%;
  }

  &Suggestions {
    position: absolute;
    z-index: 2;
    width: 100%;
    border: 1px solid $lightgrey;
  }

  &SuggestionItem {
    padding: 10px 20px;
    background-color: $white-3;

    &:not(:last-child) {
      border-bottom: 1px solid $white-2;
    }

    &:hover {
      cursor: pointer;

      span {
        color: $primaryBlue;
      }
    }
  }

  &SuggestionEmpty {
    padding: 10px 20px;
    background-color: $white-3;
  }

  &Button {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    min-width: 145px;
    height: 60px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 0.15px;
    cursor: pointer;
    background: #2378e5;
    border: 1px solid #136fd3;
    border-radius: 0 8px 8px 0;

    &:hover {
      background: #1854a3;
    }

    @include msd {
      width: auto !important;
      min-width: 97px;
      height: 42px;
      font-family: 'Product Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      color: #fff;

      /* 90% */
      letter-spacing: 0.15px;
      background: #2378e5;
      border-radius: 0 8px 0 0;
    }
  }

  @include msd {
    flex-direction: row;

    &InputContainer {
      width: 100%;
      height: 42px;

      :global {
        .MuiOutlinedInput-root {
          height: 42px;
        }

        .MuiInputLabel-root {
          top: 2px;
        }
      }
    }

    &Button {
      width: 35%;
    }
  }
}

.searchInput {
  flex: 1;

  :global {
    .MuiInputLabel-root {
      font-size: 16px;
    }
  }
}

.companyList {
  display: flex;
  flex-wrap: wrap;

  // margin-top: 20px;
  justify-content: space-between;

  @include msd {
    flex-direction: column;
    margin-top: 18px;
  }
}

.companyItem {
  width: 368px;

  // margin-right: 10px;
  margin-bottom: 15px;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease-in;

  &:hover {
    border: 1px solid #2378e5;
    box-shadow: 7px 0 70px 0 rgba(0, 0, 0, 0.1);

    .split_hover {
      width: 100%;
    }
  }

  @include msd {
    width: 100%;
  }
}

.companyVerify {
  position: relative;
  width: 30px;
  height: 30px;

  @include msd {
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 24px;
    height: 24px;
  }
}

.mobile {
  @include msd {
    display: block !important;
  }
}

.companyCard {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
  height: 300px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;

  @include msd {
    flex-direction: row;
    height: auto;
    padding: 0;
    background: transparent;
  }

  &Image {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    object-fit: contain;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
  }

  &Left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    @include msd {
      position: relative;
      width: auto;
      margin-right: 20px;
    }
  }

  &Right {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    p {
      flex: 1;
      margin: 0;
    }

    @include msd {
      width: calc(100% - 54px);
    }
  }

  &Name {
    display: flex;

    a {
      display: -webkit-box;
      margin-bottom: 6px;
      overflow: hidden;
      font-family: 'Product Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #1b1534;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @include msd {
        @include line-clamp-with-line-height(2, 1.15);

        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  &Category {
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    color: #353535;
    text-overflow: ellipsis;
    letter-spacing: 0.113px;
    white-space: nowrap;

    @include text_overflow;

    @include msd {
      width: 80%;

      /* 153.333% */
      margin-bottom: 20px !important;
      font-family: 'Product Sans';
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      color: var(--general-black, #353535);
    }
  }

  &Openings {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;

    @include msd {
      display: none;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      color: #4e5969;
      letter-spacing: 0.113px;
    }

    span {
      font-size: 16px;
      color: #2378e5;
    }
  }

  .arrowIcon {
    margin-left: 10px;
  }
}

.compnayCardWrapper {
  @include msd {
    padding: 16px 16px 14px;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
  }
}

.mobileOpenings {
  display: none;

  @include msd {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 0;
    border-top: 1px solid #e4e4e4;
  }
}

.split {
  display: block;
  width: 100%;
  border-top: 1px solid #e4e4e4;

  @include msd {
    display: none;
  }
}

.split_hover {
  width: 0;
  border-top: 1px solid #2378e5;
  transition: all 0.3s ease-in;
}

.tips_wrapper {
  position: relative;
  width: 100%;
  height: 139px;
  margin-bottom: 15px;
  border-radius: 8px;

  @include msd {
    display: none;
  }
}

.tips_full {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 139px;
  overflow: hidden;
  border-radius: 8px;
}

.tips_content {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  height: 139px;
  padding: 0 40px;
}

.tips_content_text {
  flex: 1;
  margin-right: 60px;
  margin-left: 30px;
  font-family: 'Product Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #fff;

  /* 125% */
  letter-spacing: 0.18px;
}

.tips_content_go {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 44px;
  padding: 12px 30px;
  font-size: 18px;
  color: #2378e5;
  cursor: pointer;
  background: var(--main-color-white-white-ffffff, #fff);
  border-radius: 4px;

  &:hover {
    background: darken(#fff, 10%);
  }

  &:active {
    background: darken(#fff, 20%);
  }
}

.loadMore {
  display: none;

  // height: 30px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px auto 35px;

  @include msd {
    display: flex;
  }
}

.viewMore {
  height: 40px;
  padding: 0 30px;
  color: rgb(35, 120, 229);
  background-color: transparent;
  border: 1px solid rgb(35, 120, 229);
  border-radius: 4px;
}

.benefitFilter {
  :global {
    #jobFunction {
      text-align: center;
    }
  }
}

.containerBenefits {
  margin-top: 10px;
}
